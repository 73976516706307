/* custom variables */
body {
  overflow-x: hidden;
  margin: 0;
  padding: 0;
  font-family: "Nimbus Sans", sans-serif;
  letter-spacing: 1.8px;
}

.container-fluid {
  padding-right: 25px;
  padding-left: 25px;
}

h1 {
  font-size: 28px;
  font-weight: 100;
  margin-top: 22px;
  margin-bottom: 24px;
  letter-spacing: 1.8px;
}
@media (max-width: 575.98px) {
  h1 {
    margin-top: 24px;
    margin-bottom: 18px;
    font-size: 22px;
  }
}

a {
  color: #020304;
  cursor: pointer;
  text-decoration: none;
}
a:hover {
  color: #F26854;
}

@media (max-width: 576px) {
  .projects .container-fluid,
  .contact .container-fluid,
  .clients .container-fluid,
  .project .container-fluid {
    padding-left: 15px !important;
    padding-right: 15px !important;
  }
  .project .heading,
  .project .project-details {
    padding-left: 15px !important;
    padding-right: 15px !important;
  }
}
.heading--bold {
  font-weight: 700;
}

.home {
  overflow: hidden;
}
.home .container-fluid {
  margin: 0;
  padding: 0;
}
.home h1 {
  display: none;
}

.project h1 {
  font-size: 22px;
  margin-bottom: 12px;
}
.project h3 {
  font-size: 14px;
}

/* Center the loader */
#loader {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  z-index: 100;
  width: 80px;
  height: 80px;
  background-color: rgba(255, 255, 255, 0.15);
  border: 24px solid;
  border-color: rgba(255, 255, 255, 0.15) rgba(255, 255, 255, 0.25) rgba(255, 255, 255, 0.35) rgba(255, 255, 255, 0.5);
  border-radius: 50%;
  display: inline-block;
  box-sizing: border-box;
  animation: animloader 1s linear infinite;
}

@keyframes animloader {
  0% {
    border-color: rgba(255, 255, 255, 0.15) rgba(255, 255, 255, 0.25) rgba(255, 255, 255, 0.35) rgba(255, 255, 255, 0.75);
  }
  33% {
    border-color: rgba(255, 255, 255, 0.75) rgba(255, 255, 255, 0.15) rgba(255, 255, 255, 0.25) rgba(255, 255, 255, 0.35);
  }
  66% {
    border-color: rgba(255, 255, 255, 0.35) rgba(255, 255, 255, 0.75) rgba(255, 255, 255, 0.15) rgba(255, 255, 255, 0.25);
  }
  100% {
    border-color: rgba(255, 255, 255, 0.25) rgba(255, 255, 255, 0.35) rgba(255, 255, 255, 0.75) rgba(255, 255, 255, 0.15);
  }
}
.animate-bottom {
  position: relative;
  -webkit-animation-name: animatebottom;
  -webkit-animation-duration: 1s;
  animation-name: animatebottom;
  animation-duration: 1s;
}

@-webkit-keyframes animatebottom {
  from {
    bottom: -100px;
    opacity: 0;
  }
  to {
    bottom: 0px;
    opacity: 1;
  }
}
@keyframes animatebottom {
  from {
    bottom: -100px;
    opacity: 0;
  }
  to {
    bottom: 0;
    opacity: 1;
  }
}
#content {
  animation: fadein 2s;
  -moz-animation: fadeIn 2s;
  -webkit-animation: fadeIn 2s;
  -o-animation: fadeIn 2s;
  opacity: 1;
  text-align: left;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@-moz-keyframes fadeIn {
  /* Firefox */
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@-webkit-keyframes fadeIn {
  /* Safari and Chrome */
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@-o-keyframes fadeIn {
  /* Opera */
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
.contact-details {
  line-height: 14px;
}
.contact-details h3 {
  font-size: 16px;
  font-weight: 100;
}
.contact-details p {
  font-size: 16px;
  font-weight: 100;
}
.contact-details a {
  text-decoration: underline;
}
.contact-details .landline, .contact-details .mobile, .contact-details .email, .contact-details .linkedin {
  margin-bottom: 48px;
}

.client-grid .block--client {
  min-height: 260px;
  padding-right: 30px;
}
@media (min-width: 1440px) {
  .client-grid .block--client {
    min-height: 300px;
  }
}
@media (min-width: 992px) {
  .client-grid .block--client:nth-child(4n) {
    padding-right: 15px;
  }
}
@media (max-width: 992px) {
  .client-grid .block--client:nth-child(3n) {
    padding-right: 15px;
  }
}
@media (max-width: 575px) {
  .client-grid .block--client {
    min-height: 175px;
    padding-bottom: 20px;
  }
  .client-grid .block--client p {
    margin: 0;
  }
  .client-grid .block--client:nth-child(2n) {
    padding-right: 15px;
  }
  .client-grid .block--client:nth-child(3n) {
    padding-right: 30px;
  }
  .client-grid .block--client:nth-child(6n) {
    padding-right: 15px;
  }
}
.client-grid .block--client .block--company {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 85%;
  background: #FBFBFB;
}
@media (min-width: 1440px) {
  .client-grid .block--client .block--company {
    min-height: 255px;
  }
}
.client-grid .block--client .block--company:hover {
  background: #f7f7f7;
}
.client-grid .block--client .block--company p {
  width: 55%;
  text-align: center;
  font-size: 16px;
  font-weight: 100;
}
@media (max-width: 575px) {
  .client-grid .block--client .block--company p {
    font-size: 14px;
    width: 80%;
  }
}

.home .home-gallery {
  margin: 10px 0;
}
@media (max-width: 575.98px) {
  .home .home-gallery {
    margin: 4px 0;
  }
}
.home .carousel-item img {
  width: 100%;
  height: 85vh;
  object-fit: cover;
  overflow: hidden;
}
@media (max-width: 575.98px) {
  .home .carousel-item img {
    height: 74vh;
  }
}
@media (min-width: 1440px) {
  .home .carousel-item img {
    height: 88vh;
  }
}
.home .carousel-caption {
  position: relative;
  left: initial;
  right: initial;
  top: -9px;
  padding-left: 26px;
  padding-right: 15px;
  text-align: left;
  background-color: #ffffff;
}
.home .carousel-caption h5 {
  display: inline-block;
  color: #020304;
  font-size: 16px;
  font-weight: 100;
  margin-right: 32px;
}
.home .carousel-caption h5 .caption--bold {
  font-weight: 100;
}
@media (max-width: 575.98px) {
  .home .carousel-caption h5 {
    font-size: 12px;
    font-weight: 200;
  }
}
@media (max-width: 575.98px) {
  .home .carousel-caption {
    bottom: 10px;
    padding-left: 10px;
  }
}
.home .carousel-control-next, .home .carousel-control-prev {
  width: 90px;
  opacity: initial;
}
.home .carousel-control-prev {
  left: -6px;
}
.home .carousel-control-next {
  right: -6px;
}
.home .carousel-control-next-icon, .home .carousel-control-prev-icon {
  display: inline-block;
  height: 80px;
  width: 80px;
}
.home .carousel-control-prev-icon {
  background-image: url(../images/icons/previous.svg);
}
.home .carousel-control-next-icon {
  background-image: url(../images/icons/next.svg);
}

.home-grid .block--home {
  cursor: pointer;
}
.home-grid .block--home .block--home-name {
  margin-top: 18px;
  margin-bottom: 38px;
  text-align: center;
}
.home-grid .block--home .block--home-name h2 {
  margin-left: 15px;
  font-size: 22px;
  font-weight: 100;
  line-height: 14px;
  background-color: #fff;
}
@media (max-width: 575px) {
  .home-grid .block--home .block--home-name h2 {
    font-size: 12px;
  }
}

.modal.and.carousel {
  position: fixed;
}

@media (min-width: 992px) {
  .modal .modal-dialog {
    max-width: 950px;
  }
}
.modal .modal-dialog .modal-header {
  padding: 12px;
}
.modal .modal-dialog .modal-content .modal-body {
  padding: 0;
}

.modal-backdrop.show {
  opacity: 0.8;
}

/* main navigation styles */
header {
  height: 52px;
}
@media (max-width: 575.98px) {
  header {
    height: 55px;
  }
}
header .logo {
  position: fixed;
  top: 15px;
  left: 25px;
  z-index: 6;
}
@media (max-width: 575.98px) {
  header .logo {
    left: 15px;
  }
}
header .logo .image--logo {
  width: 253px;
  height: fit-content;
}
@media (max-width: 575.98px) {
  header .logo .image--logo {
    width: 224px;
  }
}
header .input--burger-menu {
  display: none;
}
header input + label {
  font-family: "Nimbus Sans", sans-serif;
  position: fixed;
  top: 15px;
  right: 26px;
  height: 28px;
  width: 36px;
  z-index: 5;
}
@media (max-width: 575.98px) {
  header input + label {
    top: 15px !important;
    right: 15px;
    height: 26px;
    width: 30px;
  }
}
header input + label span {
  position: absolute;
  width: 100%;
  height: 4px;
  top: 50%;
  margin-top: -1px;
  left: 0;
  display: block;
  background: #020304;
  transition: 0.5s;
}
@media (max-width: 575.98px) {
  header input + label span {
    height: 2px;
  }
}
header input + label span:first-child {
  top: 2px;
}
header input + label span:last-child {
  top: 26px;
}
@media (max-width: 575.98px) {
  header input + label span:last-child {
    top: 24px;
  }
}
header label:hover {
  cursor: pointer;
}
header input:checked + label span {
  opacity: 0;
  top: 50%;
}
header input:checked + label span:first-child {
  opacity: 1;
  transform: rotate(405deg);
}
header input:checked + label span:last-child {
  opacity: 1;
  transform: rotate(-405deg);
}
header input ~ nav {
  background: rgba(255, 255, 255, 0.95);
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 60px;
  z-index: 3;
  transition: 0.5s;
  transition-delay: 0.5s;
  overflow: hidden;
}
@media (max-width: 575.98px) {
  header input ~ nav {
    height: 59px;
  }
}
header input ~ nav > ul {
  list-style: none;
  text-align: center;
  position: absolute;
  padding: 0;
  top: 15%;
  left: 20%;
  right: 20%;
}
@media (max-width: 575px) {
  header input ~ nav > ul {
    width: 100%;
    padding: 0;
    left: 0;
    right: 0;
  }
}
header input ~ nav > ul > li {
  opacity: 0;
  transition: 0.4s;
  transition-delay: 0s;
}
header input ~ nav > ul > li > a {
  text-decoration: none;
  color: #020304;
  font-weight: 400;
  font-family: "Nimbus Sans", sans-serif;
  font-size: 22px;
  display: block;
  padding: 30px;
}
@media (max-width: 575px) {
  header input ~ nav > ul > li > a {
    font-size: 16px;
  }
}
header input:checked ~ nav {
  height: 100%;
  transition-delay: 0s;
}
header input:checked ~ nav > ul > li {
  opacity: 1;
  transition-delay: 0.5s;
}

.nav__social-list {
  list-style-type: none;
  margin: 50px 0;
  padding: 0;
  overflow: hidden;
}
.nav__social-list li {
  display: inline;
  padding: 10px;
}

.project .project-details {
  margin-bottom: 15px;
}
.project .project-details h3 {
  font-weight: 100;
}
@media (max-width: 575.98px) {
  .project .project-details h3 {
    font-weight: 200;
  }
}
@media (max-width: 575.98px) {
  .project .project-details {
    margin-bottom: 60px;
  }
}
.project .project-other {
  position: absolute;
  bottom: 15px;
  left: 28px;
}
.project .project-other h4 {
  display: inline-block;
  font-size: 12px;
  font-weight: 100;
}
.project .project-other a {
  display: inline-block;
  font-size: 12px;
  font-weight: 100;
  padding: 0 12px;
}
.project .project-other a:not(:last-child) {
  border-right: 1px solid #020304;
}
.project .carousel-item {
  height: 69vh;
  text-align: center;
  width: 78vw;
}
@media (max-width: 1440px) {
  .project .carousel-item {
    height: 64vh;
  }
}
.project .carousel-item picture img {
  height: 100%;
  margin: auto;
}
.project .carousel-caption {
  position: relative;
  left: initial;
  right: initial;
  bottom: 4px;
  padding-left: 26px;
  padding-right: 15px;
  text-align: left;
}
.project .carousel-caption h5 {
  display: inline-block;
  color: #020304;
  font-size: 16px;
  font-weight: 100;
  margin-right: 32px;
}
.project .carousel-caption h5 .caption--bold {
  font-weight: 100;
}
.project .carousel-control-next, .project .carousel-control-prev {
  width: 90px;
  opacity: initial;
}
.project .carousel-control-prev {
  left: -30px;
}
.project .carousel-control-next {
  right: -30px;
}
.project .carousel-control-next-icon, .project .carousel-control-prev-icon {
  display: inline-block;
  height: 80px;
  width: 80px;
}
.project .carousel-control-prev-icon {
  background-image: url(../images/icons/previous--black.svg);
}
.project .carousel-control-next-icon {
  background-image: url(../images/icons/next--black.svg);
}

@media (min-width: 992px) {
  .projects .project-grid {
    padding-right: 0;
  }
  .projects .project-grid .col-sm-6,
  .projects .project-grid .col-md-4,
  .projects .project-grid .col-lg-4 {
    padding-right: 45px;
    margin-bottom: 45px;
  }
}
@media (min-width: 992px) {
  .projects .project-grid .project-grid--row {
    margin-right: -20px;
  }
}
.projects .project-grid .project-grid--row .block--project {
  cursor: pointer;
}
.projects .project-grid .project-grid--row .block--project img {
  width: 100%;
}
.projects .project-grid .project-grid--row .block--project:hover img {
  opacity: 0.75;
}
.projects .project-grid .project-grid--row .block--project .block--project-name p {
  text-align: center;
  font-size: 16px;
  font-weight: 100;
  line-height: 60px;
  height: 60px;
  background-color: #FBFBFB;
}
@media (max-width: 575px) {
  .projects .project-grid .project-grid--row .block--project .block--project-name p {
    font-weight: 200;
  }
}

.single-project-grid .block--project {
  padding-bottom: 60px;
}
.single-project-grid .project-other {
  position: relative;
  top: 24px;
}

/*# sourceMappingURL=custom.css.map */
